import nxModule from 'nxModule';
import _ from 'lodash';
import {addAccountLabels} from 'components/general-ledger/common/gl.utils';

const templateUrl = require('./gl-mappings.template.html');
nxModule.component('glMappings', {
  templateUrl: templateUrl,
  bindings: {
    productType: '<',
    /**
     * list of all custom fees for given product (feeClass === 'CUSTOM')
     */
    customFees: '<',
    instanceReady: '&'
  },
  controller: function ($scope, $filter, http, notification, glMappingsService, ledgerTagCache, feeDefinitionsCache, productDefinitionService) {
    const that = this;

    // List of product definitions within the same product group
    that.sourceProductTypes = [];
    // List of types of all defined ledger tags
    that.ledgerTagTypes = [];
    // List of all defined ledger tags
    that.ledgerTags = [];
    // Lest of ledger tags associated with current product
    that.productTags = [];
    // List of available GL accounts
    that.ledgerAccounts = [];

    that.emptyAccountConfig = {
      placeholder: 'Select account'
    };

    that.selectConfig = {
      placeholder: 'Select tag type',
      searchField: ['label'],
      valueField: 'type',
      labelField: 'label',
      maxItems: 1
    };

    that.$onInit = () => {
      that.instanceReady({instance: that});
    };

    const extractTagParam = (tag, paramName) => {
      if (!tag || !tag.params) return null;
      return tag.params[paramName];
    };

    const createTagLabel = (tag) => {
      // First part of tag (mandatory) is a tag type
      let tagLabel = $filter('prettyEnum')(tag.tagType);
      // If tag is status-specific -> add it
      const status = extractTagParam(tag, 'PRODUCT_STATUS');
      if (status) tagLabel = tagLabel + ' / ' + $filter('prettyEnum')(status);
      // If tag is associated with fee -> add fee name
      if (tag.feeDefinition) tagLabel = tagLabel + ' / ' + tag.feeDefinition.feeName;
      // Return final tag label

      const ropaType = extractTagParam(tag, 'ROPA_TYPE');
      if (ropaType) tagLabel = tagLabel + ' / ' + $filter('prettyEnum')(ropaType);

      const writtenOff = extractTagParam(tag, 'WRITTEN_OFF');
      if(writtenOff) tagLabel = tagLabel + ' / ' + (writtenOff === 'true' ? 'Written off' : 'Not written off');

      const device = extractTagParam(tag, 'DEVICE');
      if (device) tagLabel = tagLabel + ' / ' + device;

      const transaction = extractTagParam(tag, 'TRANSACTION');
      if (transaction) tagLabel = tagLabel + ' / ' + $filter('prettyEnum')(transaction);

      const source = extractTagParam(tag, 'SOURCE');
      if (source) tagLabel = tagLabel + ' / ' + ($filter('prettyEnum')(source)).toUpperCase();

      const destination = extractTagParam(tag, 'DESTINATION');
      if (destination) tagLabel = tagLabel + ' / ' + ($filter('prettyEnum')(destination)).toUpperCase();

      const channel = extractTagParam(tag, 'CHANNEL');
      if (channel) tagLabel = tagLabel + ' / ' + channel;

      return tagLabel;
    };

    const filterTags = (originalTags) => {
      const onlyCurrentProductTags = _.filter(originalTags, (tag) => {
        const typeId = extractTagParam(tag, 'PRODUCT_TYPE_ID');
        return typeId && Number(typeId) === Number(that.productType.id);
      });


      // Generate product tag labels
      const tagsWithLabels = _.map(onlyCurrentProductTags, (tag) => {
        return {...tag, label: createTagLabel(tag)};
      });

      return _.filter(tagsWithLabels, tag => {
        if (tag.feeDefinition && tag.feeDefinition.feeClass === 'CUSTOM') {
          if(!_.some(that.customFees, {id: tag.feeDefinition.id})) {
            console.warn('removing not existing fee ' + tag.feeDefinition.feeName);
            return false;
          }
        }
        return true;
      });
    };

    const productTypesSub = productDefinitionService.toObservable().subscribe((types) => {
      // Filter product types matching group of given [productType] & exclude given [productType]
      that.sourceProductTypes = _.filter(types, (type) => {
        return type.id !== that.productType.id && type.productGroup === that.productType.productGroup;
      });
    });

    const accountsSub = glMappingsService.accounts.toObservable().subscribe((accounts) => {
      that.ledgerAccounts = addAccountLabels(accounts);
    });

    const tagsSub = ledgerTagCache.toObservable()
      .combineLatest(feeDefinitionsCache.toObservable(), (tags, feeDefinitions) => {
        // If tag contains FEE_DEFINITION_ID param -> link tag with fee
        _.forEach(tags, (tag) => {
          const feeDefinitionId = extractTagParam(tag, 'FEE_DEFINITION_ID');
          if (feeDefinitionId && Number(feeDefinitionId) > 0) {
            const feeDefinition = _.find(feeDefinitions, {id: Number(feeDefinitionId)});
            if (feeDefinition) tag['feeDefinition'] = feeDefinition;
          }
        });
        // Follow subscription chain
        return tags;
      })
      .subscribe(tags => {
        // Keep all of the ledger tags (for copying)
        that.ledgerTags = tags;
        // Keep all of the ledger tags (for delegated mappings)
        that.ledgerTagTypes = _.map(_.uniq(_.map(tags, 'tagType')), (tagType) => {
          return {type: tagType, label: $filter('prettyEnum')(tagType)}
        }).filter(tag => !['EMPTY', 'UNDIVIDED_PROFIT_ACCOUNT'].includes(tag.type));

        // Filter product tags (tag with PRODUCT_TYPE_ID in param)
        // FIXME = filter product-related tags only first
        that.productTags = filterTags(tags);
      });

    that.onDelegateChange = (tag) => {
      tag.accountCode = null;
      tag.delegateTagType = null;
    };

    const resetTag = (tag) => {
      tag.delegate = true;
      tag.delegateTagType = null;
      tag.accountCode = null;
    };

    const findTagSibling = (tag, sourceTags) => {

      // Filter tags by type
      let siblings = _.filter(sourceTags, {tagType: tag.tagType});

      // Filter tags by product status
      if (!siblings || siblings.length === 0) return null;
      const productStatus = extractTagParam(tag, 'PRODUCT_STATUS');
      const ropaType = extractTagParam(tag, 'ROPA_TYPE');

      siblings = _.filter(siblings, (sourceTag) => {
        const sourceProductStatus = extractTagParam(sourceTag, 'PRODUCT_STATUS');
        if (!productStatus && !sourceProductStatus) return true;

        const sourceRopaType = extractTagParam(sourceTag, 'ROPA_TYPE');
        if (!ropaType && !sourceRopaType) return productStatus === sourceProductStatus;
        return ropaType === sourceRopaType;
      });

      // In case of FEE_TARGET_ACCOUNT or FEE_SOURCE_ACCOUNT -> compare [feeClass]
      if (!siblings || siblings.length === 0) return null;
      if (_.includes(['FEE_SOURCE_ACCOUNT', 'FEE_TARGET_ACCOUNT'], tag.tagType)) {
        try {
          const tagFeeClass = tag.feeDefinition.feeClass;
          siblings = _.filter(siblings, (sourceTag) => {
            const sourceFeeClass = sourceTag.feeDefinition.feeClass
            if (!tagFeeClass && !sourceFeeClass) return true;
            return tagFeeClass === sourceFeeClass;
          });
        } catch (err) {
          console.error('Failed to filter tags by fee class', err);
        }
      }

      if ('ATM_TARGET_ACCOUNT' === tag.tagType) {
        try {
          siblings = _.filter(siblings, (sourceTag) => {
            return (
              tag.definition.CHANNEL === sourceTag.definition.CHANNEL &&
              tag.definition.DEVICE === sourceTag.definition.DEVICE &&
              tag.definition.TRANSACTION === sourceTag.definition.TRANSACTION &&
              ((!tag.definition.TOPUP_TYPE && !sourceTag.definition.TOPUP_TYPE) ||
                tag.definition.TOPUP_TYPE === sourceTag.definition.TOPUP_TYPE) &&
              ((!tag.definition.SOURCE && !sourceTag.definition.SOURCE) ||
                tag.definition.SOURCE === sourceTag.definition.SOURCE) &&
              ((!tag.definition.DESTINATION && !sourceTag.definition.DESTINATION) ||
                tag.definition.DESTINATION === sourceTag.definition.DESTINATION)
            );
          });
        } catch (err) {
          console.error('Failed to filter tags by ATM definitions', err);
        }
      }

      const writtenOff = extractTagParam(tag, 'WRITTEN_OFF');
      if('LOAN_PRINCIPAL_ACCOUNT' === tag.tagType) {
        try {
          siblings = _.filter(siblings, (sourceTag) => {
            const sourceWrittenOff = extractTagParam(sourceTag, 'WRITTEN_OFF');
            return writtenOff === sourceWrittenOff;
          })
        } catch (err) {
          console.error("Failed to filter tags by loan written off definitions")
        }
      }
      
      // At this point filtration is over -> check result
      return (siblings && siblings.length === 1) ? siblings[0] : null;
    };

    that.copyMappings = (sourceTypeId) => {
      console.log('Copying mappings from: ' + that.sourceTypeId);
      // Get ledger tags from source type
      const sourceTags = _.filter(that.ledgerTags, (tag) => {
        const typeId = extractTagParam(tag, 'PRODUCT_TYPE_ID');
        return typeId && Number(typeId) === Number(sourceTypeId)
      });
      // For each product tag find matching source tag and copy it
      _.forEach(that.productTags, (tag) => {
        const sourceTag = findTagSibling(tag, sourceTags);
        if (sourceTag) {
          // Copy values from source tag to tag
          tag.delegate = sourceTag.delegate;
          tag.delegateTagType = sourceTag.delegateTagType;
          tag.accountCode = sourceTag.accountCode;

          // broadcast to the dropdown component to update the displayed selected GL Account
          $scope.$broadcast('updateInnerModelByQueryFieldValue');
        } else {
          // If no matching tag is found -> reset current value to UNMAPPED
          resetTag(tag);
        }
      });
    };

    that.resetMappings = () => {
      _.forEach(that.productTags, (tag) => resetTag(tag));
    };

    that.$onChanges = changes => {
      if(changes.productType) {
        filterTags(that.ledgerTags);
      }
    };

    /**
     * keep track of deleted custom fees since we don't
     * want to excluded them from save operation
     */
    $scope.$watchCollection('$ctrl.customFees', () => {
      that.productTags = filterTags(that.ledgerTags || []);
    });

    that.save = () => {
      // Convert GL tags config into update commands
      const commands = _.map(that.productTags, (tag) => {
        return {
          tagId: tag.id,
          delegate: tag.delegate,
          accountCode: tag.accountCode,
          comment: tag.comment,
          delegateTagType: tag.delegateTagType
        };
      });

      // Return GL modification promise
      return http.put('/ledger/tags?batch=true', commands)
        .success(() => {
          notification.show("Success", "GL mappings updated");
          ledgerTagCache.refetch();
        })
        .error(() => {
          notification.show("Error", "Failed to update GL mappings")
        });
    };

    that.hasAnyNonSavedCustomFee = () => {
      if(!that.customFees) {
        return false;
      }

      return that.customFees.some(fee => fee.id === undefined);
    };

    that.$onDestroy = () => {
      productTypesSub.unsubscribe();
      accountsSub.unsubscribe();
      tagsSub.unsubscribe();
    }
  }
});
