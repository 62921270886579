import nxModule from 'nxModule';
import _ from 'lodash';
import {GenerationStrategies} from 'constants/numberGenerator';
import ReportService from 'report/ReportService';
import {MAX_INT_32} from 'constants/validation';

const templateUrl = require('./loan-product-general.template.html');
nxModule.component('loanProductGeneralForm', {
  templateUrl,
  bindings: {
    loanProduct: '=',
    form: '=',
    branches: '<'
  },
  controller: function (depositAccountTypeService) {
    const that = this;
    const reportService = new ReportService();

    that.$onInit = async () => {
      const accountTypes = await depositAccountTypeService.toPromise();

      const filterAccounts = (accountPurpose, customerType) => {
        return _.filter(accountTypes, accountType => filterTypesForCustomerType(accountType, accountPurpose, customerType));
      };

      const emptyOption = () => ({
        value: null,
        label: 'None'
      });

      const individualCbuTypes = filterAccounts('CONTRACTUAL_SAVINGS_CBU', 'INDIVIDUAL');
      const individualPfTypes = filterAccounts('CONTRACTUAL_SAVINGS_PF', 'INDIVIDUAL');
      const individualTpTypes = filterAccounts('CONTRACTUAL_SAVINGS_TP', 'INDIVIDUAL');

      const corporateCbuTypes = filterAccounts('CONTRACTUAL_SAVINGS_CBU', 'CORPORATE');
      const corporatePfTypes = filterAccounts('CONTRACTUAL_SAVINGS_PF', 'CORPORATE');
      const corporateTpTypes = filterAccounts('CONTRACTUAL_SAVINGS_TP', 'CORPORATE');


      that.individualCbuTypes = [...individualCbuTypes.map(accountTypeMapper), emptyOption()];
      that.corporateCbuTypes = [...corporateCbuTypes.map(accountTypeMapper), emptyOption()];

      that.individualPfTypes = [...individualPfTypes.map(accountTypeMapper), emptyOption()];
      that.corporatePfTypes = [...corporatePfTypes.map(accountTypeMapper), emptyOption()];

      that.individualTpTypes = [...individualTpTypes.map(accountTypeMapper), emptyOption()];
      that.corporateTpTypes = [...corporateTpTypes.map(accountTypeMapper), emptyOption()];

      that.hasReportingToolReports = false
      reportService.readAvailableFileReports().then(reports => {
        that.hasReportingToolReports = (reports.includes('Disclosure Statement') || reports.includes('Promissory Note'));
      })
    };

    const filterTypesForCustomerType = (accountType, purpose, customerType) => {
      const forPurpose = accountType.purpose === purpose;
      const availableForCustomerType = accountType.productDefinition.customerTypeConstraints === null || accountType.productDefinition.customerTypeConstraints.includes(customerType);
      return forPurpose && availableForCustomerType;
    };

    that.assignmentStrategies = [
      {
        value: 'ALWAYS_CREATE_NEW',
        label: 'CS_ALWAYS_CREATE_NEW'
      }, {
        value: 'ASSIGN_OR_CREATE',
        label: 'CS_ASSIGN_OR_CREATE'
      }
    ];

    that.autoHoldReleaseMethods = [
      'AUTOMATIC',
      'MANUAL'
    ];

    that.autoHoldTypes = [
      'FIXED',
      'PERCENTAGE'
    ];

    that.autoHoldCalculationMethods = [
      'PERCENTAGE_OF_ORIGINAL_INTERESTS',
      'PERCENTAGE_OF_ORIGINAL_PRINCIPAL',
      'PERCENTAGE_OF_ORIGINAL_INTERESTS_AND_PRINCIPAL'
    ];

    that.collateralRequirements = [
      {
        value: 'DISALLOWED',
        label: 'CR_DISALLOWED'
      }, {
        value: 'OPTIONAL',
        label: 'CR_OPTIONAL'
      }, {
        value: 'REQUIRED',
        label: 'CR_REQUIRED'
      }
    ];

    that.depEdPolicyNumberStrategies = GenerationStrategies;
    that.maxIntegerValue = MAX_INT_32;

    that.depEdPolicyNumberOptions = [{
      header: 'Separator',
      iconClass: 'icon-minus',
      text: '\u2212',
      pattern: '-'
    }, {
      header: 'Branch code',
      iconClass: 'icon-home',
      pattern: 'B',
      text: 'B',
    }, {
      header: 'Sequence number per year',
      iconClass: 'icon-asterisk',
      pattern: 'N',
      text: 'N',
    }, {
      header: 'Month',
      iconClass: 'icon-calendar',
      pattern: 'MM',
      text: 'MM',
    }, {
      header: 'Year',
      iconClass: ' icon-calendar',
      pattern: 'YY',
      text: 'YY',
    }];

    that.onAutoHoldChange = () => {
      if (that.loanProduct.autoHold) {
        return;
      }

      that.loanProduct.autoHoldType = null;
      that.loanProduct.autoHoldFixedAmount = null;
      that.loanProduct.autoHoldPercentage = null;
      that.loanProduct.autoHoldCalculationMethod = null;
      that.loanProduct.autoHoldReleaseMethod = null;
    };

    const accountTypeMapper = (account) => {
      return {label: account.productDefinition.productName, value: account.id};
    };

    that.withContractualSavingsOnChange = () => {
      that.loanProduct.ignoreCSWhenCalculatingLoanStatus = false;
      that.loanProduct.ignoreCSWhenCalculatingAmortizationStatus = false;
      if (that.loanProduct.withContractualSavings) {
        return;
      }

      that.loanProduct.contractualSavingsAssignmentStrategy = 'ASSIGN_OR_CREATE';
      that.loanProduct.autoHold = null;
      that.loanProduct.individualCbuAccountTypeId = null;
      that.loanProduct.corporateCbuAccountTypeId = null;
      that.loanProduct.individualPfAccountTypeId = null;
      that.loanProduct.corporatePfAccountTypeId = null;
      that.loanProduct.individualTpAccountTypeId = null;
      that.loanProduct.corporateTpAccountTypeId = null;
      that.loanProduct.overPaymentStrategy = 'CREDIT_TO_GL';

      that.onAutoHoldChange();
    };

    that.onRenewalAllowedChange = () => {
      if (that.loanProduct.allowRenewal) {
        return;
      }

      that.loanProduct.renewalMinPaidPrincipalPercentage = null;
    };

    that.hasCSAccountSet = () => {
      return that.loanProduct.individualCbuAccountTypeId || that.loanProduct.corporateCbuAccountTypeId
        || that.loanProduct.individualPfAccountTypeId || that.loanProduct.corporatePfAccountTypeId
        || that.loanProduct.individualTpAccountTypeId || that.loanProduct.corporateTpAccountTypeId;
    };
  }
});
