import nxModule from 'nxModule';

import _ from 'lodash';
import {Observable} from 'rxjs/Observable'
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/first';
import {NgTableParams} from 'ng-table';
import {addAccountLabels, flattenSubAccounts} from "../../../general-ledger/common/gl.utils";


const templateUrl = require('./gl-transaction-mapping.template.html');
nxModule.component('glTransactionsMapping', {
  templateUrl,
  controller: function ($filter, $q, glTagsDescriptionCache, http, glMappingsService,
                        ledgerTagCache, feeDefinitionsCache, notification, authentication) {
    const that = this;

    that.edit = true;
    that.printBranchId = authentication.context.branchId;
    that.tagsDescription = [];
    that.tdKeys = [];
    that.ledgerAccounts = [];
    that.allLedgerAccounts = [];
    that.ledgerTags = [];
    that.nonEmptyLedgerTagTypes = [];
    that.allLedgerTagTypes = [];

    that.emptyAccountConfig = {
      placeholder: 'Select account'
    };

    that.glFilter = {
      filterName: undefined
    };

    that.selectConfig = {
      placeholder: 'Select tag type',
      searchField: ['label'],
      valueField: 'type',
      labelField: 'label',
      maxItems: 1
    };

    const extractTagParam = (tag, paramName) => {
      if (!tag || !tag.params) return null;
      return tag.params[paramName];
    };

    const concatMappingName = (name, description, key) => {
      const separator = " | ";
      if (that.tagsDescription[description][key]) {
        return name.length > 0 ? separator.concat(that.tagsDescription[description][key]) : that.tagsDescription[description][key];
      }
      return "";
    };

    const createMappingName = tag => {
      let name = tag.comment || '';

      if (that.tagsDescription) {
        if (tag.params.REFERENCE_BRANCH_ID && that.tagsDescription.REFERENCE_BRANCH_ID) {
          name = name.concat(concatMappingName(name, "REFERENCE_BRANCH_ID", tag.params.REFERENCE_BRANCH_ID));
        }

        if (tag.params.BRANCH_ID && that.tagsDescription.BRANCH_ID) {
          name = name.concat(concatMappingName(name, "BRANCH_ID", tag.params.BRANCH_ID));
        }

        if (tag.params.DEPOSITORY_ACCOUNT_ID && that.tagsDescription.DEPOSITORY_ACCOUNT_ID) {
          name = name.concat(concatMappingName(name, "DEPOSITORY_ACCOUNT_ID", tag.params.DEPOSITORY_ACCOUNT_ID));
        }

        if (tag.params.ASSET_COUNTER_SUBTYPE_ID && that.tagsDescription.ASSET_COUNTER_SUBTYPE_ID) {
          name = name.concat(concatMappingName(name, "ASSET_COUNTER_SUBTYPE_ID", tag.params.ASSET_COUNTER_SUBTYPE_ID));
        }

        if (tag.params.PRODUCT_TYPE_ID && that.tagsDescription.PRODUCT_TYPE_ID) {
          name = name.concat(concatMappingName(name, "PRODUCT_TYPE_ID", tag.params.PRODUCT_TYPE_ID));
        }

        if (tag.params.MIS_CODE && that.tagsDescription.MIS_CODE) {
          name = name.concat(concatMappingName(name, "MIS_CODE", tag.params.MIS_CODE));
        }

        if (tag.params.RESTRUCTURED && that.tagsDescription.RESTRUCTURED) {
          name = name.concat(concatMappingName(name, "RESTRUCTURED", tag.params.RESTRUCTURED));
        }

        if (tag.params.PRODUCT_STATUS && that.tagsDescription.PRODUCT_STATUS) {
          name = name.concat(concatMappingName(name, "PRODUCT_STATUS", tag.params.PRODUCT_STATUS));
        }

        if (tag.params.FEE_DEFINITION_ID && that.tagsDescription.FEE_DEFINITION_ID) {
          name = name.concat(concatMappingName(name, "FEE_DEFINITION_ID", tag.params.FEE_DEFINITION_ID));
        }

        if (tag.params.ATM_ID && that.tagsDescription.ATM_ID) {
          name = name.concat(concatMappingName(name, "ATM_ID", tag.params.ATM_ID));
        }

        if (tag.params.ROPA_TYPE && that.tagsDescription.ROPA_TYPE) {
          name = name.concat(concatMappingName(name, "ROPA_TYPE", tag.params.ROPA_TYPE));
        }

        if (tag.params.EXTERNAL_TRANSFER_CHANNEL && that.tagsDescription.EXTERNAL_TRANSFER_CHANNEL) {
          name = name.concat(concatMappingName(name, "EXTERNAL_TRANSFER_CHANNEL", tag.params.EXTERNAL_TRANSFER_CHANNEL))
        }

        if (tag.params.EXTERNAL_TRANSFER_TYPE && that.tagsDescription.EXTERNAL_TRANSFER_TYPE) {
          name = name.concat(concatMappingName(name, "EXTERNAL_TRANSFER_TYPE", tag.params.EXTERNAL_TRANSFER_TYPE))
        }

        if (tag.params.REFERRAL_BONUS_TYPE && that.tagsDescription.REFERRAL_BONUS_TYPE) {
          name = name.concat(concatMappingName(name, "REFERRAL_BONUS_TYPE", tag.params.REFERRAL_BONUS_TYPE))
        }

        if (tag.params.DEVICE && that.tagsDescription.DEVICE) {
          name = name.concat(concatMappingName(name, "DEVICE", tag.params.DEVICE))
        }

        if (tag.params.TRANSACTION && that.tagsDescription.TRANSACTION) {
          name = name.concat(concatMappingName(name, "TRANSACTION", tag.params.TRANSACTION))
        }

        if (tag.params.SOURCE && that.tagsDescription.SOURCE) {
          name = name.concat(concatMappingName(name, "SOURCE", tag.params.SOURCE))
        }

        if (tag.params.DESTINATION && that.tagsDescription.DESTINATION) {
          name = name.concat(concatMappingName(name, "DESTINATION", tag.params.DESTINATION))
        }

        if (tag.params.TOPUP_TYPE && that.tagsDescription.TOPUP_TYPE) {
          name = name.concat(concatMappingName(name, "TOPUP_TYPE", tag.params.TOPUP_TYPE))
        }

        if (tag.params.CHANNEL && that.tagsDescription.CHANNEL) {
          name = name.concat(concatMappingName(name, "CHANNEL", tag.params.CHANNEL))
        }
      }

      return name;
    };

    const tagsPromise = http.get('/ledger/tags').toPromise();

    const tagsObservable = Observable.fromPromise(tagsPromise);

    const getDataPromise = tagsObservable.combineLatest(feeDefinitionsCache.toObservable(), glTagsDescriptionCache.toObservable(),
      (tags, feeDefinitions, tagDesc) => {

        that.tagsDescription = tagDesc;
        that.ledgerTags = tags;
        that.allLedgerTagTypes = _.map(_.uniq(_.map(that.ledgerTags, 'tagType')).filter(tagType => tagType !== 'UNDIVIDED_PROFIT_ACCOUNT'), (tagType) => {
          return {type: tagType, label: $filter('prettyEnum')(tagType)};
        });

        that.nonEmptyLedgerTagTypes = that.allLedgerTagTypes.filter(tag => tag.type !== 'EMPTY');

        const allowableTagsToMap = tags.filter(tag => tag.tagType !== 'EMPTY');
        return _.map(_.sortBy(allowableTagsToMap, "tagType"), (tag) => {
          tag.mappingName = createMappingName(tag);
          tag.filterName = tag.tagType + " | " + tag.mappingName;
          return tag;
        });
      }).first().toPromise();

    that.tableConfig = new NgTableParams({}, {});

    const accountSub = glMappingsService.accounts.toObservable().subscribe((accounts) => {
      that.ledgerAccounts = addAccountLabels(accounts);
    });

    const allAccountSub = glMappingsService.allAccounts.toObservable().subscribe((accounts) => {
      const flatAccounts = flattenSubAccounts(accounts);
      that.allLedgerAccounts = addAccountLabels(flatAccounts);
    });

    that.saveTag = (tag) => {
      const command = {
        tagId: tag.id,
        delegate: tag.delegate,
        accountCode: tag.accountCode,
        comment: tag.comment,
        delegateTagType: tag.delegateTagType
      };
      const commands = [command];

      // Return GL modification promise
      return http.put('/ledger/tags?batch=true', commands)
        .success(() => {
          notification.show("Success", "GL mappings updated");
          ledgerTagCache.refetch();
        })
        .error(response => {
          notification.show("Error", response?.errorMessage ? response.errorMessage : "Failed to update GL mappings");
        });
    };

    const tagTypesEmptyAllowed = ['LABELED_ACCOUNT', 'MIS_UID', 'MIS_ASSET', 'MIS_INTEREST_ACCRUAL', 'MIS_INCOME', 'MIS_FEE_ACCRETION', 'MIS_FEE_DISCOUNT'];

    that.getTagTypes = (tag) => {
      if (tagTypesEmptyAllowed.includes(tag.tagType)) {
        return that.allLedgerTagTypes;
      }
      return that.nonEmptyLedgerTagTypes;
    };

    that.onDelegateChange = (tag) => {
      tag.accountCode = null;
      tag.delegateTagType = null;
    };

    that.print = () => {
      window.print();
    };

    that.onEdit = () => {
      that.edit = !that.edit;
      that.tableConfig = new NgTableParams({
        page: 1,
        count: 10,
        filter: that.glFilter,
      }, {
        // if edit = enabled -> disable possibility to change page size
        // cause enabling editing on size > 10 may cause page crash
        counts: that.edit ? [] : [10, 25, 50, that.ledgerTags.length],
        paginationMaxBlocks: 8,
        paginationMinBlocks: 3,
        total: that.ledgerTags.length,
        dataset: that.data
      });
    };

    that.$onInit = async () => {
      that.data = await getDataPromise;

      that.tableConfig = new NgTableParams({
        page: 1,
        count: 10,
        filter: that.glFilter,
      }, {
        counts: [],
        paginationMaxBlocks: 8,
        paginationMinBlocks: 3,
        total: that.ledgerTags.length,
        dataset: that.data
      });
    };

    that.$onDestroy = () => {
      accountSub.unsubscribe();
      allAccountSub.unsubscribe();
    };
  }
});
